// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// https://getbootstrap.com/docs/5.0/customize/options/
$enable-rounded: true;
$offcanvas-vertical-height: 75vh;
$enable-caret: true;

$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;
$gray-500: #adb5bd;
$black: #000000;
$white: #ffffff;

$primary: #0D6EFD;
$secondary: #6C757D;
$success: #198754;
$info: #0DCAF0;
$warning: #FFC107;
$danger: #DC3545;
$light: #F8F9FA;
$dark: #212529;

$link-color: #0d6efd;
