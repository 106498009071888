// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// Font Awesome Free
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

// Cropper JS
@import '~cropperjs/dist/cropper.css'; // TODO: Check if working

// Slick Slider
@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';

// Slick Changes
@import 'slick';

// Microtip
@import '~microtip/microtip';

// Animate on Scroll
@import "~aos/dist/aos.css";

// SimpleMDE
@import "~simplemde/dist/simplemde.min.css";

// Custom CSS
@import 'custom';
