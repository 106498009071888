
// Project CSS Goes Here

.ar-container {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    background-position: top;
    background-size: cover;
}

.ar-16x9 {
    padding-top: 56.25%;
}

.trbl {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
